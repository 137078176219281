<template>
  <div class="body">
    <div class="front-stuff">
      <div class="header">
        <h1> This is a list of some businesses I support and personally recommend!</h1>
        <h5> If you have any questions or just need information just reach out!</h5>
      </div>
      <RecCard
          scroll-to="scissors-scotch"
          title="Scissors & Scotch"
          url="https://www.scissorsscotch.com/"
      >
        <h5>Why I recommend them</h5>
        <p>Scissors and Scotch has a fully stocked upscale bar and you get a free drink with every haircut! Their
          haircuts are pretty fantastic too. I always see Andrea!</p>
        <h5>Freebies</h5>
        <p>If you mentioned that I said you can use one of my 'VIP passes' you can get your first cut for free! Doesn't
          cost a thing.</p>
      </RecCard>
      <RecCard
          scroll-to="vero"
          title="Vero Chiropractic"
          url="https://www.verochiropractic.com/"
      >
        <h5>Why I recommend them</h5>
        <p>I've been going to Vero for over a year now and their neurological approach to health and wellness is second
          to none. They've helped me a ton with back and neck pain!</p>
        <h5>Freebies</h5>
        <p>If you're interested, let me know! I have a few coupons for a free first visit.</p>
      </RecCard>
      <RecCard
          scroll-to="farrells"
          title="Farrell’s eXtreme Bodyshaping: Jordan Creek"
          url="https://fxbjordancreek.com/"
      >
        <h5>Why I recommend them</h5>
        <p>I've been a member of Farrell's for years, I love the Jordan Creek location! If you're looking for a way to
          shake up your current or maybe start a new fitness routine, you should check out a class! The owners Tracy and
          Eric White are both fantastic people and the culture in general is very supportive and encouraging!</p>
        <h5>Freebies</h5>
        <p>If you reach out to me, there are free days that you can visit class to see if it's for you!</p>
      </RecCard>
      <RecCard
          scroll-to="imt"
          title="IMT Insurance"
          url="https://imtins.com/"
      >
        <h5>Why I recommend them</h5>
        <p>I've worked for IMT as a web developer since graduating in May of '19. The platform team as well as the
          company as a whole are shocking amazing people. The culture is great and the number one reason I'm proud to be
          an employee. I.S. is usually hiring so reach out if you're a developer looking for a job! I'd be happy to put
          in recommendations for qualified friends!</p>
      </RecCard>
      <RecCard
          scroll-to="upstream"
          title="Upstream Gardens"
          url="https://upstreamgardens.com/"
      >
        <h5>Why I recommend us</h5>
        <p>You know you can't beat our local honey ;) </p>
      </RecCard>
      <RecCard
          scroll-to="mifiber"
          title="Mi-Fiber internet"
          url="https://mi-fiber.net/"
      >
        <h5>Why I recommend them</h5>
        <p>Mi-Fiber is hands down the best internet I've ever had. The support is amazing and of all the things on this
          list, I recommend them most highly!</p>
        <h5>Freebies</h5>
        <p>If you mention I referred you, you get a free first month of service!</p>
      </RecCard>
      <RecCard
          scroll-to="commons"
          title="The Commons at Greenway Park"
          url="https://www.commonsgreenwaypark.com/"
          :last-item="true"
      >
        <h5>Why I recommend them</h5>
        <p>I moved here in August of 2020, and while brand new construction wasn't without hiccups, overall I've been
          extremely pleased with the townhouse so far! Feel free to let me know if you have any questions about
          them.</p>
        <h5>Freebies</h5>
        <p>If you mention I referred you we both get a $100 rent credit!</p>
      </RecCard>
    </div>
    <div class="background-shit">
      <div class="wrap">
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
        <div class="c"></div>
      </div>
    </div>
  </div>
</template>

<script>
import RecCard from "@/components/RecCard";

export default {
  name: "Recommendations",
  components: {
    RecCard
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.sass';

.body {
  width: 100%;
  padding: 5rem;
}

.front-stuff {
  position: relative;
  z-index: 5;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media (max-width: $phone-breakpoint) {
  .body {
    padding: 5rem 0 0 0;
    width: 100%;
  }
  .front-stuff {
    width: 100%;
  }
}


.header {
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center !important;
}

.background-shit {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

// Particle stuff below
$total: 5000;
$orb-size: 500px;
$particle-size: 5px;
$time: 20s;
$base-hue: 200;

html, body {
  height: 100%;
}

body.wrap {
  background: black;
  overflow: hidden; // no scrollbars..
}

.wrap {
  position: relative;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transform-style: preserve-3d;
  perspective: 1000px;
  animation: rotate $time infinite linear; // rotate orb
}

@keyframes rotate {
  100% {
    transform: rotateY(360deg) rotateX(360deg);
  }
}

.c {
  position: absolute;
  width: $particle-size;
  height: $particle-size;
  border-radius: 50%;
  opacity: 0;
}

@for $i from 1 through $total {
  $z: (random(360) * 1deg); // random angle to rotateZ
  $y: (random(360) * 1deg); // random to rotateX
  $hue: ((40/$total * $i) + $base-hue); // set hue

  .c:nth-child(#{$i}) { // grab the nth particle
    animation: orbit#{$i} $time infinite;
    animation-delay: ($i * .01s);
    background-color: hsla($hue, 100%, 50%, 1);
  }

  @keyframes orbit#{$i}{
    20% {
      opacity: 1; // fade in
    }
    30% {
      transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // form orb
    }
    80% {
      transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // hold orb state 30-80
      opacity: 1; // hold opacity 20-80
    }
    100% {
      transform: rotateZ(-$z) rotateY($y) translateX(($orb-size * 3)) rotateZ($z); // translateX * 3
    }
  }
}
</style>
